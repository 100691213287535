<!-- src/components/About.vue -->
<template>
   <div class="cover-container d-flex h-100 p-3 mx-auto flex-column">
    <header class="masthead mb-auto">
      <nav>
        <ul>
          <li><RouterLink to="/">Home</RouterLink></li>
          <li><RouterLink to="/entrance">Entrance</RouterLink></li>
          <li><RouterLink to="/rooms">Rooms</RouterLink></li>
        </ul>
      </nav>
    </header>
  </div>
</template>

<script>
export default {
  name: 'HomePage',
  data() {
    return {
      // Add your data properties here
    };
  },
  methods: {
    // Add your methods here
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
