<!-- src/components/Entrance.vue -->
<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
  </div>
  <h1 class="display-3 text-dark shadow">{{ time }}</h1>
  <div class="table-responsive shadow-lg rounded">
    <table class="table table-striped table-bordered" style="opacity: 0.92;">
      <thead class="thead-light table-primary">
        <tr>
          <td style="width: 20%">Date</td>
          <td style="width: 10%">Time</td>
          <td style="width: 20%">Room</td>
          <td style="width: 20%">Programme</td>
          <td style="width: 30%">Detail</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id" style="vertical-align: middle">
          <td style="height: 100px; vertical-align: middle" class="fs-2">
            {{ formatDate(item.date) }}
          </td>
          <td style="vertical-align: middle" class="fs-2">{{ item.time }}</td>
          <td style="vertical-align: middle" class="fs-1">
            {{ item.roomName }}
            <p class="small fs-4 fw-lighter">{{ findRoom(item.roomName) }}</p>
          </td>
          <td style="vertical-align: middle" class="fs-1">
            <p class="fs-2">
              {{ getEmoji(item.eventType) }} {{ item.eventType }}
            </p>
          </td>
          <td style="vertical-align: middle" class="fs-1">
            <p class="fs-3 fw-light">{{ item.title }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div
    id="background-image"
    :style="{
      backgroundImage: `url(assets/images/entranceBackgrounds/${backgroundImage}.jpg)`
    }"
  ></div>
</template>

<script>
import { getJsonContent } from "../data/getJsonContent.js";
import { format, isDate } from "date-fns";

export default {
  name: "EntrancePage",
  data() {
    return {
      items: [],
      time: "",
      loading: false,
      reloadInterval: null,
      backgroundImage: "",
      format
    };
  },
  mounted() {
    this.fetchData();

    // Set up interval to refresh data every 90 seconds
    this.reloadInterval = setInterval(() => {
      location.reload();
    }, 1000 * 90);

    setInterval(() => {
      this.time = this.returnTime();
    }, 1000);

    // get random background image
    const imageCount = 27;
    const randomIndex = Math.floor(Math.random() * imageCount);
    this.backgroundImage =
      randomIndex < 10 ? `0${randomIndex}` : randomIndex.toString();
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.reloadInterval);
  },
  methods: {
    fetchData() {
      this.loading = true;
      getJsonContent()
        .then((data) => {
          this.items = data;
        })
        .catch((error) => {
          console.error("Error fetching JSON content:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(incomingDate) {
      const date = new Date(incomingDate);
      if (isDate(date)) {
        return format(date, "E, do MMM");
      }
      return "";
    },
    getEmoji(eventType) {
      switch (eventType) {
        case "Akhand Paath":
        case "Gurbani Class":
        case "Katha":
        case "Paath":
        case "Sukhmani Sahib":
          return "📖";
        case "Akhand Paath Arambh":
          return "📖 ✅";
        case "Akhand Paath Bhog":
          return "📖 👋🏾";
        case "Jaap":
          return "📿";
        case "Langar":
          return "🍲";
        case "Asa Di Vaar":
        case "Keertan Class":
        case "Keertan Darbaar":
          return "🎵";
        case "Birthday":
          return "🎂";
        case "Punjabi Class":
          return "🏫";
        case "Workshop":
          return "🧮";
        case "Yoga":
          return "🪷";
        default:
          return "";
      }
    },
    findRoom(roomname) {
      switch (roomname) {
        case "Darbar Sahib 1":
        case "Langar Hall 1":
          return "ground floor 🔽";
        case "Darbar Sahib 2":
        case "Langar Hall 2":
          return "1st floor 🔼";
        case "Darbar Sahib 3":
        case "Classroom 1":
        case "Classroom 2":
          return "2nd floor ⏫";
        case "Outside Hall":
          return "outside";
        default:
          return "";
      }
    },
    returnTime() {
      const today = new Date();

      // get time components
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();

      //add '0' to hour, minute & second when they are less 10
      const hour = hours < 10 ? "0" + hours : hours;
      const minute = minutes < 10 ? "0" + minutes : minutes;
      const second = seconds < 10 ? "0" + seconds : seconds;

      //make clock a 12-hour time clock
      const hourTime = hour > 12 ? hour - 12 : hour;

      // if (hour === 0) {
      //   hour = 12;
      // }
      //assigning 'am' or 'pm' to indicate time of the day
      const ampm = hour < 12 ? "AM" : "PM";

      //get current date and time
      const date = format(new Date(), "eeee, do MMMM");
      const time = `${hourTime}:${minute}:${second} ${ampm}`;

      //combine current date and time
      const dateTime = date + " - " + time;
      return dateTime;
    }
  }
};
</script>

<style scoped>
/* Assign grid areas */
.content {
  grid-area: content;
}

/* Add your styles here */
table {
  width: 100%;
  font-size: xx-large;
  border-collapse: collapse;
}

thead {
  font-size: xx-large;
  text-align: center;
  color: red;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
  text-align: left;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
