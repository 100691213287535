// src/main.js
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";

// Import Bootstrap and BootstrapVue CSS
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "./assets/styles.css";

// Import BootstrapVue 3
import BootstrapVue3 from "bootstrap-vue-3";

createApp(App).use(router).use(BootstrapVue3).mount("#app");
