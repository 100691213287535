<!-- src/components/Rooms.vue -->
<template>
  <article>
    <h1>Rooms</h1>
    <p>This is the Rooms page.</p>
  </article>
</template>

<script>
export default {
  name: 'RoomsPage',
  data() {
    return {
      // Add your data properties here
    };
  },
  methods: {
    // Add your methods here
  },
};
</script>

<style scoped>
/* Add your styles here */
</style>
